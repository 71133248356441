// src/pages/ArticlesList.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillLike, AiOutlineLike } from "react-icons/ai"; // 點讚/沒點讚圖示
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"; // 收藏/未收藏圖示
import { IoStatsChart } from "react-icons/io5"; // 瀏覽次數圖示

function ArticlesList() {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // 搜尋詞狀態
  const [currentPage, setCurrentPage] = useState(1); // 當前頁碼狀態
  const itemsPerPage = 20; // 每頁顯示的文章數量
  const { t } = useTranslation(); // 使用 i18n

  useEffect(() => {
    async function fetchArticles() {
      try {
        const response = await axios.get(`${API_URL}/api/articles`);
        setArticles(response.data);
      } catch (err) {
        console.error('獲取文章列表時發生錯誤：', err);
      }
    }
    fetchArticles();
  }, []);

  // 根據搜尋詞過濾文章
  const filteredArticles = articles.filter(article =>
    article.title && article.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 計算顯示在當前頁的文章
  const startIdx = (currentPage - 1) * itemsPerPage;
  const paginatedArticles = filteredArticles.slice(startIdx, startIdx + itemsPerPage);

  // 計算總頁數
  const totalPages = Math.ceil(filteredArticles.length / itemsPerPage);

  // 處理頁碼更改
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 處理搜尋框變動，重置頁碼
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // 搜尋時重置頁碼為第一頁
  };

  // 處理點讚
  const handleLike = async (id) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/articles/${id}/like`, 
        {}, 
        { withCredentials: true }
      );
      setArticles(articles.map(article => 
        article._id === id 
          ? { ...article, likes: response.data.likeCount } 
          : article
      ));
    } catch (err) {
      console.error('更新點讚數時發生錯誤：', err);
    }
  };
  


  // 處理收藏
  const handleFavorite = async (id) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/articles/${id}/favorite`, 
        {}, 
        { withCredentials: true }
      );
      setArticles((prevArticles) =>
        prevArticles.map(article =>
          article._id === id ? { ...article, isFavorited: response.data.favorited } : article
        )
      );
      toast.success(response.data.favorited ? t('addedToFavorites') : t('removedFromFavorites'));
    } catch (err) {
      console.error('更新收藏狀態時發生錯誤：', err);
      toast.error(t('favoriteError'));
    }
  };



  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <ToastContainer />
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-2xl font-bold mb-4">{t('articles')}</h2>

        {/* 搜尋框 */}
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={t('search')}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
          />
        </div>

        {/* 文章列表 */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {paginatedArticles.map((article) => (
            <div key={article._id} className="p-4 border rounded bg-white dark:bg-gray-800 shadow-md hover:shadow-lg transition-shadow duration-200">
              <h3 className="text-xl font-semibold">{article.title}</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {new Date(article.createdAt).toLocaleString()}
              </p>
              <div className="flex items-center space-x-2 mt-2">
              <button onClick={() => handleLike(article._id)} className="text-red-500 flex items-center space-x-1">
                  {article.likes > 0 ? <AiFillLike /> : <AiOutlineLike />}
                  <span>{Array.isArray(article.likes) ? article.likes.length : article.likes || 0}</span>
                </button>

                <button onClick={() => handleFavorite(article._id)} className="text-yellow-500 flex items-center space-x-1">
                  {article.isFavorited ? <MdFavorite /> : <MdFavoriteBorder />}
                  <span>{article.isFavorited ? t('favorited') : t('favorite')}</span>
                </button>

                <span className="flex items-center space-x-1">
                  <IoStatsChart />
                  <span>{article.views || 0}</span>
                </span>
              </div>
              <Link
                to={`/articles/${article._id}`}
                className="text-blue-500 hover:underline mt-2 inline-block"
              >
                {t('readMore')}
              </Link>
            </div>
          ))}
        </div>

        {/* 分頁按鈕 */}
        <div className="mt-6 flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded ${currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 dark:bg-gray-700 dark:text-white'
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ArticlesList;
