// src/pages/ResourcesList.js
import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function ResourcesList() {
  const [resources, setResources] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchResources() {
      try {
        const response = await axios.get(`${API_URL}/api/resources`);
        setResources(response.data);
      } catch (err) {
        console.error('獲取資源列表時發生錯誤：', err);
      }
    }
    fetchResources();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-3xl font-bold mb-6">{t('resources')}</h2>
        <div className="grid grid-cols-1 gap-4">
          {resources.map((resource) => (
            <div key={resource._id} className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">{resource.title}</h3>
              <a
                href={resource.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {t('downloadResource')}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResourcesList;
