// fronted/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { fetchCsrfToken } from './utils/axiosConfig';

(async () => {
  try {
    await fetchCsrfToken();
    console.log('CSRF token initialized successfully');
  } catch (error) {
    console.error('Failed to initialize CSRF token. The application may not function correctly.', error);
  }
})();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
