// src/pages/VideoDetail.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useParams } from 'react-router-dom';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';

function VideoDetail() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchVideo() {
      try {
        const response = await axios.get(`${API_URL}/api/videos/${id}`);
        setVideo(response.data);
      } catch (err) {
        console.error('獲取影音詳情時發生錯誤：', err);
      }
    }
    fetchVideo();
  }, [id]);

  // 格式化 YouTube URL 為嵌入格式
  const getEmbedUrl = (url) => {
    if (url.includes('watch?v=')) {
      return url.replace('watch?v=', 'embed/');
    }
    return url;
  };

  if (!video) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">{t('loading')}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white px-4 py-6">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-center md:text-left">{video.title}</h1>

        <div className="flex justify-center mb-6">
          <iframe
            className="w-full md:w-3/4 lg:w-1/2 aspect-video rounded-lg shadow-md"
            src={getEmbedUrl(video.url)}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-2xl font-semibold mb-4">{t('summary')}</h2>
          <p className="text-gray-700 dark:text-gray-300">{video.summary}</p>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">{t('details')}</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-2"><strong>{t('views')}: </strong>{video.views}</p>
          <p className="text-gray-700 dark:text-gray-300 mb-2"><strong>{t('likes')}: </strong>{video.likes}</p>
          <p className="text-gray-700 dark:text-gray-300 mb-2"><strong>{t('tags')}: </strong>{video.tags ? video.tags.join(', ') : t('noTags')}</p>
          <p className="text-gray-700 dark:text-gray-300"><strong>{t('uploadDate')}: </strong>{new Date(video.createdAt).toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
}

export default VideoDetail;
