// frontend/src/pages/MyReports.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import MemberSidebar from '../components/MemberSidebar'; // 引入 MemberSidebar 組件
import { useNavigate } from 'react-router-dom'; 
import { toast } from "react-toastify";

function MyReports() {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [guestId, setGuestId] = useState('');
  const [message, setMessage] = useState('');
  const [guestReport, setGuestReport] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    axios
      .get(`${API_URL}/api/my_reports`, { withCredentials: true })
      .then((res) => {
        setReports(res.data.reports);
      })
      .catch((err) => {
        console.error(t('errorFetchingReports'), err);
      });

    const storedGuestId = localStorage.getItem('guestId');
    if (storedGuestId) {
      setGuestId(storedGuestId);
      axios
        .get(`${API_URL}/api/guest_report/${storedGuestId}`)
        .then((res) => {
          setGuestReport(res.data.report);
        })
        .catch((err) => {
          console.error(t('errorFetchingGuestReport'), err);
        });
    }
  }, [t]);

  const handleExport = (reportId, format) => {
    window.open(`${API_URL}/api/export_report/${reportId}?format=${format}`, '_blank');
  };

  const handleAddToFavorites = (reportId) => {
    axios
      .post(`${API_URL}/api/add_to_favorites`, { reportId }, { withCredentials: true })
      .then(() => {
        toast.success(t('addedToFavorites'));
      })
      .catch((err) => {
        console.error(t('errorAddingToFavorites'), err);
      });
  };

  const handleLinkReport = () => {
    axios
      .post(`${API_URL}/api/link_report`, { guestId }, { withCredentials: true })
      .then(() => {
        setMessage(t('reportLinked'));
        setGuestId('');
        setGuestReport(null);
        localStorage.removeItem('guestId');
      })
      .catch((err) => {
        console.error(t('errorLinkingReport'), err);
        setMessage(t('errorLinkingReport'));
      });
  };

  // 點擊報告詳情
  const handleOpenReport = (reportId) => {
    navigate(`/report/${reportId}`);
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      {/* 頁面橫幅 */}
      <div
        className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >
        {t('myReports')}
      </div>

      <div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto mt-6 px-4">
        {/* 左側頭像和導航區 */}
        <MemberSidebar activePage="myReports" />

        {/* 右側報案記錄顯示區 */}
        <div className="flex-1 ml-0 md:ml-6">
          {/* 關聯訪客報案區 */}
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
            <h3 className="text-lg font-semibold mb-4">{t('linkGuestReport')}</h3>
            <input
              type="text"
              value={guestId}
              onChange={(e) => setGuestId(e.target.value)}
              placeholder={t('enterGuestId')}
              className="w-full p-2 border rounded mb-4"
            />
            {/* 顯示 guestReport 的內容 */}
            {guestReport && (
              <div className="mt-4">
                <p>{t('reportId')}: {guestReport._id}</p>
                <p>{t('incidentType')}: {guestReport.incidentType}</p>
                <p>{t('description')}: {guestReport.description}</p>
                {/* 可以根據需要顯示更多 guestReport 的其他欄位 */}
              </div>
            )}
            <button
              onClick={handleLinkReport}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {t('linkReport')}
            </button>
            {message && <p className="mt-4 text-green-500">{message}</p>}
          </div>


          {/* 報案記錄條列展示 */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg mb-8">
            <h2 className="text-2xl font-bold mb-4">
              {t('myReports')} ({reports.length})
            </h2>
            <div className="space-y-4">
              {reports.map((report) => (
                <div
                  key={report._id}
                  className="flex flex-col md:flex-row items-start md:items-center p-4 border-b border-gray-200 dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={() => handleOpenReport(report._id)}
                >
                  <div className="flex-1 mb-4 md:mb-0">
                    <p className="text-lg font-semibold">
                      {t('reportId')}: {report._id}
                    </p>
                    <p>{t('incidentType')}: {t(report.incidentType)}</p>
                  </div>
                  {/* <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // 防止事件冒泡
                        handleExport(report._id, 'pdf');
                      }}
                      className="bg-blue-500 text-white px-3 py-1 rounded"
                    >
                      {t('exportAsPDF')}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // 防止事件冒泡
                        handleExport(report._id, 'json');
                      }}
                      className="bg-blue-500 text-white px-3 py-1 rounded"
                    >
                      {t('exportAsJSON')}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // 防止事件冒泡
                        handleAddToFavorites(report._id);
                      }}
                      className="bg-yellow-500 text-white px-3 py-1 rounded"
                    >
                      {t('addToFavorites')}
                    </button>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyReports;
