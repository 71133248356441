// src/pages/VideosList.js
import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function VideosList() {
  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // 搜尋詞狀態
  const [currentPage, setCurrentPage] = useState(1); // 當前頁碼狀態
  const itemsPerPage = 20; // 每頁顯示的影音數量
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchVideos() {
      try {
        const response = await axios.get(`${API_URL}/api/videos`);
        setVideos(response.data);
      } catch (err) {
        console.error('獲取影音列表時發生錯誤：', err);
      }
    }
    fetchVideos();
  }, []);

  // 根據搜尋詞過濾影音
  const filteredVideos = videos.filter(video =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 計算顯示在當前頁的影音
  const startIdx = (currentPage - 1) * itemsPerPage;
  const paginatedVideos = filteredVideos.slice(startIdx, startIdx + itemsPerPage);

  // 計算總頁數
  const totalPages = Math.ceil(filteredVideos.length / itemsPerPage);

  // 處理頁碼更改
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 處理搜尋框變動，重置頁碼
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // 搜尋時重置頁碼為第一頁
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-3xl font-bold mb-6">{t('videos')}</h2>

        {/* 搜尋框 */}
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={t('search')}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
          />
        </div>

        {/* 影音列表 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {paginatedVideos.map((video) => (
            <div key={video._id} className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">{video.title}</h3>
              <iframe
                width="100%"
                height="215"
                src={video.link}
                title={video.title}
                allowFullScreen
                className="rounded-lg"
              ></iframe>
            </div>
          ))}
        </div>

        {/* 分頁按鈕 */}
        <div className="mt-6 flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded ${currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 dark:bg-gray-700 dark:text-white'
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideosList;
