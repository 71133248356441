import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../utils/axiosConfig'; // CSRF設定

function BecomePartner() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    companyName: '',
    contactName: '',
    phone: '',
    email: '',
    desiredFeatures: '',
    assistance: '',
    otherContactDetails: '',
    additionalMessage: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(false); // 電子郵件的錯誤狀態

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === 'email' && value) {
      setEmailError(false); // 清除電子郵件錯誤
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // 驗證電子郵件是否填寫
    if (!formData.email) {
      setEmailError(true); // 顯示電子郵件錯誤
      toast.error(t('emailRequired'));
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${API_URL}/api/partner`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        toast.success(t('formSubmittedSuccessfully'));

        // 重置表單
        setFormData({
          companyName: '',
          contactName: '',
          phone: '',
          email: '',
          desiredFeatures: '',
          assistance: '',
          otherContactDetails: '',
          additionalMessage: '',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(t('formSubmissionFailed'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">{t('becomePartner')}</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* 公司名稱 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('companyName')}
          </label>
          <input
            type="text"
            value={formData.companyName}
            onChange={(e) => handleChange('companyName', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('companyNamePlaceholder')}
          />
        </div>

        {/* 聯絡人姓名 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('contactName')}
          </label>
          <input
            type="text"
            value={formData.contactName}
            onChange={(e) => handleChange('contactName', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('contactNamePlaceholder')}
          />
        </div>

        {/* 電話 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('phone')}
          </label>
          <input
            type="text"
            value={formData.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('phonePlaceholder')}
          />
        </div>

        {/* 電子郵件 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('email')}<span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            className={`w-full p-2 border rounded ${
              emailError ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } bg-white dark:bg-gray-800 dark:text-gray-100`}
            placeholder={t('emailPlaceholder')}
          />
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{t('emailRequired')}</p>
          )}
        </div>

        {/* 許願未來功能 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('desiredFeatures')}
          </label>
          <textarea
            value={formData.desiredFeatures}
            onChange={(e) => handleChange('desiredFeatures', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('desiredFeaturesPlaceholder')}
          />
        </div>

        {/* 可以提供的協助 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('assistance')}
          </label>
          <textarea
            value={formData.assistance}
            onChange={(e) => handleChange('assistance', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('assistancePlaceholder')}
          />
        </div>

        {/* 其他聯絡資訊 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('otherContactDetails')}
          </label>
          <textarea
            value={formData.otherContactDetails}
            onChange={(e) => handleChange('otherContactDetails', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('otherContactDetailsPlaceholder')}
          />
        </div>

        {/* 想說的話 */}
        <div>
          <label className="block text-lg font-medium mb-2 text-gray-900 dark:text-gray-100">
            {t('additionalMessage')}
          </label>
          <textarea
            value={formData.additionalMessage}
            onChange={(e) => handleChange('additionalMessage', e.target.value)}
            className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-gray-100"
            placeholder={t('additionalMessagePlaceholder')}
          />
        </div>

        {/* 提交按鈕 */}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`px-4 py-2 rounded text-white ${
            isSubmitting ? 'bg-gray-400' : 'bg-blue-500 dark:bg-blue-700'
          }`}
        >
          {isSubmitting ? t('submitting') : t('submit')}
        </button>
      </form>
    </div>
  );
}

export default BecomePartner;
