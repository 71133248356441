// src/pages/AdminVideos.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function AdminVideos() {
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchVideos() {
      try {
        const response = await axios.get(`${API_URL}/api/admin/videos`, { withCredentials: true });
        setVideos(response.data);
      } catch (err) {
        console.error('獲取影音時發生錯誤：', err);
        if (err.response && err.response.status === 401) {
          navigate('/login');
        } else if (err.response && err.response.status === 403) {
          alert(t('accessDenied'));
          navigate('/');
        }
      }
    }
    fetchVideos();
  }, [navigate, t]);

  const handleDelete = async (videoId) => {
    try {
      await axios.delete(`${API_URL}/api/admin/videos/${videoId}`, { withCredentials: true });
      setVideos(videos.filter(video => video._id !== videoId));
    } catch (err) {
      console.error('刪除影音時發生錯誤：', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-2xl font-bold mb-4">{t('videoManagement')}</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('title')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('createdAt')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {videos.map(video => (
                <tr key={video._id} className="text-center">
                  <td className="px-4 py-2 border-b dark:border-gray-700">{video.title}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">{new Date(video.createdAt).toLocaleString()}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">
                    <button
                      onClick={() => navigate(`/admin/videos/edit/${video._id}`)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      {t('edit')}
                    </button>
                    <button
                      onClick={() => handleDelete(video._id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      {t('delete')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminVideos;
