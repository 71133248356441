// src/pages/EditVideo.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function EditVideo() {
  const { id } = useParams(); // 從路徑中獲取 ID 參數
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [tags, setTags] = useState('');
  const [url, setUrl] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // 獲取影音的詳細資訊
    async function fetchVideo() {
      try {
        const response = await axios.get(`${API_URL}/api/videos/${id}`);
        const video = response.data;
        setTitle(video.title);
        setSummary(video.summary);
        setTags(video.tags.join(', '));
        setUrl(video.url);
      } catch (err) {
        console.error('獲取影音資料時發生錯誤：', err);
      }
    }
    fetchVideo();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const tagsArray = tags.split(',').map(tag => tag.trim()); // 將標籤轉為陣列
      await axios.put(
        `${API_URL}/api/admin/videos/${id}`,
        { title, summary, tags: tagsArray, url },
        { withCredentials: true }
      );
      navigate('/admin/videos'); // 成功後導航回影音列表
    } catch (err) {
      console.error('更新影音時發生錯誤：', err);
      alert(t('errorUpdatingVideo'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white flex items-center justify-center">
      <div className="w-full max-w-lg bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">{t('editVideo')}</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">{t('videoTitle')}</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('videoSummary')}</label>
            <textarea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('videoTags')}</label>
            <input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder={t('tagsPlaceholder')}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('videoUrl')}</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition"
          >
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditVideo;
