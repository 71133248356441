import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Maintenance() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="h-screen flex flex-col justify-center items-center bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-white">
            <h1 className="text-4xl font-bold mb-4">{t('maintenanceMode')}</h1>
            <p className="text-xl mb-6">{t('maintenanceMessage')}</p>
            <button
                onClick={() => navigate('/')}
                className="bg-blue-500 text-white px-6 py-3 rounded"
            >
                {t('goToHome')}
            </button>
        </div>
    );
}

export default Maintenance;
