import React, { useState } from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const mdParser = new MarkdownIt();

function AdminCreateArticle() {
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [translation, setTranslation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const tagsArray = tags.split(',').map((tag) => tag.trim());

      await axios.post(
        `${API_URL}/api/admin/articles`,
        { title, summary, content, tags: tagsArray, translations: { en: translation } },
        { withCredentials: true }
      );

      setSuccess(t('articleCreatedSuccess'));
      setTimeout(() => navigate('/admin/articles'), 1500);
    } catch (err) {
      console.error('創建文章時發生錯誤：', err);
      setError(t('errorCreatingArticle'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-2xl font-bold mb-4">{t('createArticle')}</h2>

        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1">{t('title')}</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
              required
            />
          </div>
          <div>
            <label className="block mb-1">{t('summary')}</label>
            <textarea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
            />
          </div>
          <div>
            <label className="block mb-1">{t('content')}</label>
            <MdEditor
              value={content}
              style={{ height: '500px' }}
              renderHTML={(text) => mdParser.render(text)}
              onChange={({ text }) => setContent(text)}
            />
          </div>
          <div>
            <label className="block mb-1">{t('tags')}</label>
            <input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder={t('tagsPlaceholder')}
              className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
            />
          </div>
          <div>
            <label className="block mb-1">{t('translation')}</label>
            <textarea
              value={translation}
              onChange={(e) => setTranslation(e.target.value)}
              placeholder={t('translationPlaceholder')}
              className="w-full p-2 border rounded bg-white dark:bg-gray-800 dark:text-white"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminCreateArticle;
