// App.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Home from './pages/Home';
import Result from './pages/Result';
import Report from './pages/Report';
import WalletRisk from './pages/WalletRisk';
import PathAnalysis from './pages/PathAnalysis';
import BlockchainAcademy from './pages/BlockchainAcademy';
import AIRiskPrediction from './pages/AIRiskPrediction';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import MyReports from './pages/MyReports';
import Favorites from './pages/Favorites';
import ReportDetails from './pages/ReportDetails';

import Navbar from './components/Navbar'; 
import Footer from './components/Footer';
import './i18n';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeedbackButton from './components/FeedbackButton';
import ScrollToTop from './components/ScrollToTop';

import AdminDashboard from './pages/AdminDashboard';
import AdminUsers from './pages/AdminUsers';
import AdminArticles from './pages/AdminArticles';
import AdminVideos from './pages/AdminVideos';
import AdminCreateArticle from './pages/AdminCreateArticle';
import AdminCreateVideo from './pages/AdminCreateVideo';
import ArticlesList from './pages/ArticlesList';
import VideosList from './pages/VideosList';
import ArticleDetail from './pages/ArticleDetail';
import VideoDetail from './pages/VideoDetail';
import EditArticle from './pages/EditArticle';
import EditVideo from './pages/EditVideo';
import ResourcesList from './pages/ResourcesList';

import ApiUsagePage from './pages/ApiUsagePage';
import Maintenance from './pages/Maintenance';
import ErrorBoundaryWrapper from './components/ErrorBoundary';
import BecomePartner from './pages/BecomePartner';

// import CookieConsent from './components/CookieConsent';

// 初始化 Google Analytics
ReactGA.initialize('G-RBK4ZP0177');

const TrackRouteChange = () => {
  const location = useLocation();
  React.useEffect(() => {
    // 追蹤頁面瀏覽
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  return null;
};

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  React.useEffect(() => {
    const handleUnhandledRejection = (event) => {
      console.error('Unhandled Promise Rejection:', event.reason);
      if (process.env.NODE_ENV === 'production') {
        // 只有在生產環境時才跳轉到維修頁面
        window.location.href = '/maintenance';
      } else {
        // 開發環境打印錯誤但不跳轉
        console.warn('Unhandled Promise Rejection in development:', event.reason);
      }
    };
  
    window.addEventListener('unhandledrejection', handleUnhandledRejection);
  
    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);
  



  return (
    <Router>
      <ScrollToTop />
      <TrackRouteChange />
      <ErrorBoundaryWrapper>
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
        <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        {/* <CookieConsent /> */}

        <div className="pt-16">
          <Routes>
            <Route path="/" element={<Home darkMode={darkMode} />} />
            <Route path="/wallet-risk" element={<WalletRisk />} />
            <Route path="/result" element={<Result />} />
            <Route path="/report" element={<Report />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-reports" element={<MyReports />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/path-analysis" element={<PathAnalysis />} />
            <Route path="/academy" element={<BlockchainAcademy />} />
            <Route path="/ai-prediction" element={<AIRiskPrediction />} />
            <Route path="/report/:id" element={<ReportDetails />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/articles" element={<AdminArticles />} />
            <Route path="/admin/articles/create" element={<AdminCreateArticle />} />
            <Route path="/articles/:id" element={<ArticleDetail />} />
            <Route path="/admin/articles/edit/:id" element={<EditArticle />} />
            <Route path="/admin/videos" element={<AdminVideos />} />
            <Route path="/admin/videos/create" element={<AdminCreateVideo />} />
            <Route path="/academy/articles" element={<ArticlesList />} />
            <Route path="/academy/videos" element={<VideosList />} />
            <Route path="/academy/resources" element={<ResourcesList />} />
            <Route path="/videos/:id" element={<VideoDetail />} />
            <Route path="/admin/videos/edit/:id" element={<EditVideo />} />
            <Route path="/admin/api-usage" element={<ApiUsagePage />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/become-partner" element={<BecomePartner />} />

          </Routes>
        </div>
        <FeedbackButton /> 
        <Footer darkMode={darkMode} />
      </div>
      </ErrorBoundaryWrapper>
    </Router>
  );
}

export default App;
