// src/pages/ArticleDetail.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useParams } from 'react-router-dom'; // 用於獲取路由參數
import { useTranslation } from 'react-i18next'; // 引入 i18n
import ReactMarkdown from 'react-markdown'; // 引入 react-markdown
import remarkGfm from 'remark-gfm'; // 支持 GitHub 樣式的 Markdown，如表格、刪除線等
import rehypeRaw from 'rehype-raw'; // 允許解析 HTML 標籤

function ArticleDetail() {
  const [article, setArticle] = useState(null);
  const { id } = useParams(); // 獲取文章 ID
  const { t } = useTranslation(); // 使用 i18n

  useEffect(() => {
    async function fetchArticle() {
      try {
        const response = await axios.get(`${API_URL}/api/articles/${id}`);
        setArticle(response.data);
      } catch (err) {
        console.error('獲取文章時發生錯誤：', err);
      }
    }
    fetchArticle();
  }, [id]);

  if (!article) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white flex items-center justify-center">
        {t('loading')}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      {/* 頁面容器 */}
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-3xl font-bold mb-4">{article.title}</h1>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
          {new Date(article.createdAt).toLocaleString()}
        </p>
        <div className="prose dark:prose-dark max-w-none">
          <ReactMarkdown
            children={article.content}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </div>
    </div>
  );
}

export default ArticleDetail;
