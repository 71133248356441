import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import ReactMarkdown from 'react-markdown'; // 引入 react-markdown
import remarkGfm from 'remark-gfm'; // 支援 GFM (如表格、列表)

function BlockchainAcademy() {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function fetchArticles() {
      try {
        const response = await axios.get(`${API_URL}/api/articles`);
        setArticles(response.data.slice(0, 3));
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    }
    fetchArticles();
  }, []);

  useEffect(() => {
    async function fetchVideos() {
      try {
        const response = await axios.get(`${API_URL}/api/videos`);
        setVideos(response.data.slice(0, 3));
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    }
    fetchVideos();
  }, []);

  const handleLike = async (id, type) => {
    try {
      const response = await axios.post(`${API_URL}/api/${type}/${id}/like`, {}, { withCredentials: true });
      if (type === 'articles') {
        setArticles(articles.map(article =>
          article._id === id ? { ...article, likes: response.data.likeCount } : article
        ));
      } else if (type === 'videos') {
        setVideos(videos.map(video =>
          video._id === id ? { ...video, likes: response.data.likeCount } : video
        ));
      }
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  const handleFavorite = async (id, type) => {
    try {
      const response = await axios.post(`${API_URL}/api/${type}/${id}/favorite`, {}, { withCredentials: true });
      if (type === 'articles') {
        setArticles(articles.map(article =>
          article._id === id ? { ...article, isFavorited: response.data.favorited } : article
        ));
      } else if (type === 'videos') {
        setVideos(videos.map(video =>
          video._id === id ? { ...video, isFavorited: response.data.favorited } : video
        ));
      }
      toast.success(response.data.favorited ? t('addedToFavorites') : t('removedFromFavorites'));
    } catch (error) {
      console.error('Error updating favorite status:', error);
      toast.error(t('favoriteError'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      <ToastContainer />
      <div className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >{t('blockchainAcademy')}
      </div>

      <div className="container mx-auto px-4 py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Link
            to="/academy/articles"
            className="relative bg-cover bg-center rounded-lg shadow-md hover:shadow-lg transition-all"
            style={{
              backgroundImage: `url('/images/articlesList_img.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="p-6">
              <h2 className="relative text-2xl font-semibold text-black mb-4">
                {t('articles')}
              </h2>
            </div>
          </Link>
          <Link
            to="/academy/videos"
            className="relative bg-cover bg-center rounded-lg shadow-md hover:shadow-lg transition-all"
            style={{
              backgroundImage: `url('/images/videoList_img.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="p-6">
              <h2 className="relative text-2xl font-semibold text-black mb-4">
                {t('videos')}
              </h2>
            </div>
          </Link>
        </div>

        <section className="mt-10">
          <h3 className="text-3xl font-bold mb-6">{t('latestArticles')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6">
            {articles.length > 0 && (
              <div className="md:col-span-2 md:row-span-2 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all relative">
                <span className="absolute top-2 left-2 bg-yellow-400 text-white px-2 py-1 rounded">{articles[0].tags[0]}</span>
                <button onClick={() => handleFavorite(articles[0]._id, 'articles')} className="absolute top-2 right-2 text-red-500 text-2xl">
                  {articles[0].isFavorited ? <MdFavorite /> : <MdFavoriteBorder />}
                </button>
                <h4 className="text-2xl font-semibold mb-2 mt-8">{articles[0].title}</h4>
                <ReactMarkdown
                  className="text-gray-600 dark:text-gray-400 mb-4"
                  children={articles[0].content.substring(0, 500)}
                  remarkPlugins={[remarkGfm]}
                />
                <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 text-sm">
                  <div className="flex space-x-4">
                    <span className="flex items-center space-x-1">
                      <IoStatsChart /> <span>{articles[0].views || 'N/A'}</span>
                    </span>
                    <button onClick={() => handleLike(articles[0]._id, 'articles')} className="text-red-500 flex items-center space-x-1">
                    <AiFillLike /> 
                      <span>
                        {articles[0]?.likes ? 
                          (Array.isArray(articles[0].likes) ? articles[0].likes.length : articles[0].likes) 
                          : 0}
                      </span>
                    </button>

                  </div>
                  <Link to={`/articles/${articles[0]._id}`} className="bg-gray-200 dark:bg-gray-700 px-4 py-1 rounded">
                    {t('learnMore')}
                  </Link>
                </div>
              </div>
            )}
            {articles.slice(1, 3).map((article, index) => (
              <div key={index} className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all relative">
                <span className="absolute top-2 left-2 bg-blue-400 text-white px-2 py-1 rounded">{article.tags[0]}</span>
                <button onClick={() => handleFavorite(article._id, 'articles')} className="absolute top-2 right-2 text-red-500 text-2xl">
                  {article.isFavorited ? <MdFavorite /> : <MdFavoriteBorder />}
                </button>
                <h4 className="text-lg font-semibold mb-2 mt-8">{article.title}</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4 line-clamp-2">{article.content}</p>
                <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 text-sm">
                  <div className="flex space-x-4">
                    <span className="flex items-center space-x-1">
                      <IoStatsChart /> <span>{article.views || 'N/A'}</span>
                    </span>
                    <button onClick={() => handleLike(articles[0]._id, 'articles')} className="text-red-500 flex items-center space-x-1">
                    <AiFillLike /> 
                      <span>
                        {articles[0]?.likes ? 
                          (Array.isArray(articles[0].likes) ? articles[0].likes.length : articles[0].likes) 
                          : 0}
                      </span>
                    </button>

                  </div>
                  <Link to={`/articles/${article._id}`} className="bg-gray-200 dark:bg-gray-700 px-4 py-1 rounded">
                    {t('learnMore')}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="mt-10">
          <h3 className="text-3xl font-bold mb-6">{t('latestVideos')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6">
            {videos.length > 0 && (
              <div className="md:col-span-2 md:row-span-2 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all relative">
                <span className="absolute top-2 left-2 bg-yellow-400 text-white px-2 py-1 rounded">{videos[0].tags[0]}</span>
                <button onClick={() => handleFavorite(videos[0]._id, 'videos')} className="absolute top-2 right-2 text-red-500 text-2xl">
                  {videos[0].isFavorited ? <MdFavorite /> : <MdFavoriteBorder />}
                </button>
                <h4 className="text-2xl font-semibold mb-2 mt-8">{videos[0].title}</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4 line-clamp-2">{videos[0].description}</p>
                <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 text-sm">
                  <div className="flex space-x-4">
                    <span className="flex items-center space-x-1">
                      <IoStatsChart /> <span>{videos[0].views || 'N/A'}</span>
                    </span>
                    <button onClick={() => handleLike(articles[0]._id, 'articles')} className="text-red-500 flex items-center space-x-1">
                    <AiFillLike /> 
                      <span>
                        {articles[0]?.likes ? 
                          (Array.isArray(articles[0].likes) ? articles[0].likes.length : articles[0].likes) 
                          : 0}
                      </span>
                    </button>

                  </div>
                  <Link to={`/videos/${videos[0]._id}`} className="bg-gray-200 dark:bg-gray-700 px-4 py-1 rounded">
                    {t('learnMore')}
                  </Link>
                </div>
              </div>
            )}
            {videos.slice(1, 3).map((video, index) => (
              <div key={index} className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all relative">
                <span className="absolute top-2 left-2 bg-blue-400 text-white px-2 py-1 rounded">{video.tags[0]}</span>
                <button onClick={() => handleFavorite(video._id, 'videos')} className="absolute top-2 right-2 text-red-500 text-2xl">
                  {video.isFavorited ? <MdFavorite /> : <MdFavoriteBorder />}
                </button>
                <h4 className="text-lg font-semibold mb-2 mt-8">{video.title}</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4 line-clamp-2">{video.description}</p>
                <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 text-sm">
                  <div className="flex space-x-4">
                    <span className="flex items-center space-x-1">
                      <IoStatsChart /> <span>{video.views || 'N/A'}</span>
                    </span>
                    <button onClick={() => handleLike(video._id, 'videos')} className="text-red-500 flex items-center space-x-1">
                    <AiFillLike /> 
                      <span>
                        {videos[0]?.likes ? 
                          (Array.isArray(videos[0].likes) ? videos[0].likes.length : videos[0].likes) 
                          : 0}
                      </span>
                    </button>

                  </div>
                  <Link to={`/videos/${video._id}`} className="bg-gray-200 dark:bg-gray-700 px-4 py-1 rounded">
                    {t('learnMore')}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default BlockchainAcademy;
