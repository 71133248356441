// src/pages/EditArticle.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function EditArticle() {
  const { id } = useParams(); // 從路徑中獲取文章 ID
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // 獲取文章的詳細資訊
    async function fetchArticle() {
      try {
        const response = await axios.get(`${API_URL}/api/articles/${id}`);
        const article = response.data;
        setTitle(article.title);
        setSummary(article.summary);
        setContent(article.content);
        setTags(article.tags.join(', '));
      } catch (err) {
        console.error('獲取文章資料時發生錯誤：', err);
      }
    }
    fetchArticle();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const tagsArray = tags.split(',').map(tag => tag.trim());
      await axios.put(
        `${API_URL}/api/admin/articles/${id}`,
        { title, summary, content, tags: tagsArray },
        { withCredentials: true }
      );
      navigate('/admin/articles'); // 成功後導航回文章列表
    } catch (err) {
      console.error('更新文章時發生錯誤：', err);
      alert(t('errorUpdatingArticle'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white flex items-center justify-center">
      <div className="w-full max-w-lg bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">{t('editArticle')}</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">{t('articleTitle')}</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('articleSummary')}</label>
            <textarea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('articleContent')}</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white h-64"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t('articleTags')}</label>
            <input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder={t('tagsPlaceholder')}
              className="w-full p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition"
          >
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditArticle;
