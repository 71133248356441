// src/pages/AdminArticles.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next'; // 引入 i18n
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate

function AdminArticles() {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation(); // 使用 i18n
  const navigate = useNavigate(); // 用於導航

  useEffect(() => {
    async function fetchArticles() {
      try {
        const response = await axios.get(`${API_URL}/api/admin/articles`, { withCredentials: true });
        setArticles(response.data);
      } catch (err) {
        console.error('獲取文章時發生錯誤：', err);
        if (err.response && err.response.status === 401) {
          // 未登入，重定向到登入頁面
          navigate('/login');
        } else if (err.response && err.response.status === 403) {
          // 無權限，顯示錯誤訊息或重定向
          alert(t('accessDenied'));
          navigate('/');
        }
      }
    }
    fetchArticles();
  }, [navigate, t]);

  const handleDelete = async (articleId) => {
    try {
      await axios.delete(`${API_URL}/api/admin/articles/${articleId}`, { withCredentials: true });
      setArticles(articles.filter(article => article._id !== articleId));
    } catch (err) {
      console.error('刪除文章時發生錯誤：', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      {/* 頁面容器 */}
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-2xl font-bold mb-4">{t('articleManagement')}</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('title')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('createdAt')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {articles.map(article => (
                <tr key={article._id} className="text-center">
                  <td className="px-4 py-2 border-b dark:border-gray-700">{article.title}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">{new Date(article.createdAt).toLocaleString()}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">
                    <button
                      onClick={() => {
                        // 實現編輯功能
                        navigate(`/admin/articles/edit/${article._id}`);
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      {t('edit')}
                    </button>
                    <button
                      onClick={() => handleDelete(article._id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      {t('delete')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminArticles;
