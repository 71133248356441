// Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import '../i18n';
import { GlobeAltIcon } from '@heroicons/react/24/solid'; 
import { IoMdMoon } from "react-icons/io";
import { IoSunny } from "react-icons/io5";
import { Bars3Icon } from '@heroicons/react/24/outline';

// **引入 ReactGA 模組**
import ReactGA from 'react-ga4';

function Navbar({ toggleDarkMode, darkMode }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);

  let timeoutId = null;
  // console.log("env", process.env.NODE_ENV);
  // console.log(`${API_URL}/api/current_user`);

  const googleLoginUrl = process.env.NODE_ENV === 'production'
    ? `${API_URL}/api/auth/google`
    : 'http://localhost:5002/api/auth/google';

  const googleLogoutUrl = process.env.NODE_ENV === 'production'
    ? `${API_URL}/api/logout`
    : 'http://localhost:5002/api/logout';

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    async function checkAuth() {
      try {
        const response = await axios.get(`${API_URL}/api/current_user`, { withCredentials: true });
        if (response.data) {
          setIsAuthenticated(true);
          setUserId(response.data._id);
          setUser(response.data);
          // console.log("User:", response.data); 
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setAuthChecked(true);
      }
    }
    checkAuth();
  }, []);

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsDropdownOpen(true);
  };

  // **處理導航點擊事件，並發送事件到 Google Analytics**
  const handleNavClick = (label = 'Unknown', category = 'Navigation', action = 'Click') => {
    if (!label) {
      console.error('ReactGA Event Error: label is required');
      return;
    }
  
    ReactGA.event({
      category, // 默認類別
      action,   // 默認行為
      label,    // 動態標籤
    });
  };
  
  

  return (
<nav
  className={`fixed top-0 w-full z-50 p-2 ${
    darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
  } shadow-lg`}
  style={{ backdropFilter: 'blur(10px)' }}
>
  <div className="container mx-auto flex justify-between items-center">
    {/* 添加 logo 圖片 */}
    <Link to="/" onClick={() => handleNavClick('Home')} className="flex items-center">
      <img src="/damenKYT-logo.png" alt="DamenKYT Logo" className="h-16 w-16" />
      <span className={`text-2xl font-bold relative transition-colors ${
        darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
      }`}>
        {/* DamenKYT */}
      </span>
      <span
        className="absolute top-0 right-0 transform translate-x-full -translate-y-1/2 border-2 border-red-600 text-red-600 text-xs font-semibold px-1.5 py-0.5 rounded-full"
      >
        Beta
      </span>
    </Link>

        <div className="hidden md:flex items-center space-x-4">
          <Link to="/report" onClick={() => handleNavClick('Generate Report')} className={`transition-colors ${
            darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
          }`}>
            {t('generateReport')}
          </Link>
          <Link to="/wallet-risk" onClick={() => handleNavClick('Wallet Risk Query')} className={`transition-colors ${
            darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
          }`}>
            {t('walletRiskQuery')}
          </Link>


          {/* <Link
            to="/academy"
            onClick={() => handleNavClick('Blockchain Academy')}
            className={`transition-colors ${
              darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
            }`}
          >
            {t('blockchainAcademy')}
          </Link> */}



          {authChecked && isAuthenticated && (
            <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <button onClick={() => handleNavClick('Member Area')} className={`transition-colors ${
                darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
              }`}>
                {t('memberArea')}
              </button>
              {isDropdownOpen && (
                <div className={`absolute right-0 mt-2 w-48 rounded-lg shadow-lg z-10 ${
                  darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
                }`}>
                  <ul className="py-2">
                    {/* <li>
                      <Link to="/dashboard" onClick={() => handleNavClick('Dashboard')} className={`block px-4 py-2 transition-colors ${
                        darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                      }`}>
                        {t('dashboard')}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile" onClick={() => handleNavClick('Profile')} className={`block px-4 py-2 transition-colors ${
                        darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                      }`}>
                        {t('memberInfo')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-reports" onClick={() => handleNavClick('My Reports')} className={`block px-4 py-2 transition-colors ${
                        darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                      }`}>
                        {t('myReports')}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/path-analysis" onClick={() => handleNavClick('Path Analysis')} className={`block px-4 py-2 transition-colors ${
                        darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                      }`}>
                        {t('pathAnalysis')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/favorites" onClick={() => handleNavClick('Favorites')} className={`block px-4 py-2 transition-colors ${
                        darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                      }`}>
                        {t('favorites')}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              )}
            </div>
          )}

            {/* Admin Dashboard 連結 */}
            {authChecked && isAuthenticated && user && user.role === 'admin' && (
              <Link to="/admin" onClick={() => handleNavClick('Admin Dashboard')} className={`transition-colors ${
                darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
              }`}>
                {t('adminDashboard')}
              </Link>
            )}

          {authChecked && (
            isAuthenticated ? (
              <a href={googleLogoutUrl} onClick={() => handleNavClick('Logout')} className="bg-gray-400 text-white bg-opacity-50 p-2 rounded-full shadow-lg hover:bg-gray-900 hover:text-white transition-colors">
                {t('logout')}
              </a>
            ) : (
              <a href={googleLoginUrl} onClick={() => handleNavClick('Login')} className="bg-gray-100 text-gray-800 bg-opacity-50 p-2 rounded-full shadow-lg hover:bg-gray-700 hover:text-white transition-colors">
                {t('login')}
              </a>
            )
          )}

          <button onClick={() => { changeLanguage(i18n.language === 'zh' ? 'en' : 'zh'); handleNavClick('Change Language'); }}
            className="bg-gray-100 text-gray-800 bg-opacity-50 p-2 rounded-full shadow-lg hover:bg-gray-700 hover:text-white transition-colors flex items-center"
          >
            <GlobeAltIcon className="h-5 w-5 mr-1" />
            {i18n.language === 'zh' ? 'EN' : '中文'}
          </button>

          <button
            onClick={() => { toggleDarkMode(); handleNavClick('Toggle Dark Mode'); }}
            className={`w-16 h-10 flex items-center rounded-full p-1 border-4 ${
              darkMode ? 'border-gray-400 bg-purple-200' : 'border-gray-100 bg-yellow-200'
            } shadow-md transition-colors duration-300`}
          >
            <div
              className={`w-6 h-6 rounded-full transform transition-transform duration-300 flex items-center justify-center shadow-lg ${
                darkMode
                  ? 'bg-purple-400 text-purple-900 translate-x-6'
                  : 'bg-yellow-400 text-white translate-x-0'
              }`}
            >
              {darkMode ? (
                <IoMdMoon className="w-4 h-4" />
              ) : (
                <IoSunny className="w-4 h-4" />
              )}
            </div>
          </button>
        </div>

        {/* 手機版選單按鈕 */}
        <button onClick={() => { setIsMobileMenuOpen(!isMobileMenuOpen); handleNavClick('Toggle Mobile Menu'); }} className="md:hidden p-2">
          <span className="sr-only">Open mobile menu</span>
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>

      {/* 手機版選單 */}
      {isMobileMenuOpen && (
        <div className={`md:hidden z-50 ${darkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-90 text-${darkMode ? 'white' : 'black'} p-4 flex flex-col gap-2`}>
          <button onClick={() => { navigate('/report'); handleNavClick('Generate Report'); setIsMobileMenuOpen(false);}} className={`w-full text-left transition-colors ${
            darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
          }`}>
            {t('generateReport')}
          </button>
          <button onClick={() => { navigate('/wallet-risk'); handleNavClick('Wallet Risk Query'); setIsMobileMenuOpen(false); }} className={`w-full text-left transition-colors ${
            darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
          }`}>
            {t('walletRiskQuery')}
          </button>
          {authChecked && isAuthenticated && (
            <div className="relative">
              <button
                onClick={() => { setIsDropdownOpen(!isDropdownOpen); handleNavClick('Member Area'); }}
                className={`w-full text-left transition-colors ${
                  darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
                }`}>
                {t('memberArea')}
              </button>
              {isDropdownOpen && (
                <div className={`rounded-lg shadow-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}> 
                  <ul className="py-2">
                    {/* <li>
                      <Link to="/dashboard" onClick={() => {handleNavClick('Dashboard'); setIsMobileMenuOpen(false); }} className={`block px-4 py-2 transition-colors ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}> 
                        {t('dashboard')}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile" onClick={() => {handleNavClick('Profile'); setIsMobileMenuOpen(false); }} className={`block px-4 py-2 transition-colors ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}> 
                        {t('memberInfo')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-reports" onClick={() => {handleNavClick('My Reports'); setIsMobileMenuOpen(false); }} className={`block px-4 py-2 transition-colors ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}> 
                        {t('myReports')}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/path-analysis" onClick={() => {handleNavClick('Path Analysis'); setIsMobileMenuOpen(false); }} className={`block px-4 py-2 transition-colors ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}> 
                        {t('pathAnalysis')}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/favorites" onClick={() => {handleNavClick('Favorites'); setIsMobileMenuOpen(false); }} className={`block px-4 py-2 transition-colors ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}> 
                        {t('favorites')}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              )}
            </div>
          )}     

          {/* Admin Dashboard 連結 */}
          {authChecked && isAuthenticated && user && user.role === 'admin' && (
            <button onClick={() => { navigate('/admin'); handleNavClick('Admin Dashboard'); setIsMobileMenuOpen(false); }} className={`w-full text-left transition-colors ${
              darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
            }`}>
              {t('adminDashboard')}
            </button>
          )}

          {authChecked && (
            isAuthenticated ? (
              <a href={googleLogoutUrl} onClick={() => handleNavClick('Logout')} className="w-20 bg-gray-400 text-white bg-opacity-50 p-2 rounded-full shadow-lg hover:bg-gray-900 hover:text-white transition-colors text-center mt-2">
                {t('logout')}
              </a>
            ) : (
              <a href={googleLoginUrl} onClick={() => handleNavClick('Login')} className="w-20 bg-gray-100 text-gray-800 bg-opacity-50 p-2 rounded-full shadow-lg hover:bg-gray-700 hover:text-white transition-colors text-center mt-2">
                {t('login')}
              </a>
            )
          )}

          <button onClick={() => { changeLanguage(i18n.language === 'zh' ? 'en' : 'zh'); handleNavClick('Change Language'); }}
            className="w-20 block bg-gray-100 text-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-700 hover:text-white transition-colors flex items-center mt-2"
          >
            <GlobeAltIcon className="h-5 w-5 mr-1" />
            {i18n.language === 'zh' ? 'EN' : '中文'}
          </button>

          <button onClick={() => { toggleDarkMode(); handleNavClick('Toggle Dark Mode'); }} className={`block w-16 h-10 flex items-center rounded-full p-1 border-4 shadow-md transition-colors duration-300 ${
              darkMode ? 'border-gray-400 bg-purple-200' : 'border-gray-100 bg-yellow-200'
            }`}
            >
            <div
              className={`w-6 h-6 rounded-full transform transition-transform duration-300 flex items-center justify-center shadow-lg ${
                darkMode
                  ? 'bg-purple-400 text-purple-900 translate-x-10'
                  : 'bg-yellow-400 text-white translate-x-0'
              }`}
            >
              {darkMode ? (
                <IoMdMoon className="w-4 h-4" />
              ) : (
                <IoSunny className="w-4 h-4" />
              )}
            </div>
          </button>
        </div>
      )}


    </nav>
  );
}

export default Navbar;
