// src/pages/AdminDashboard.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useNavigate, Link } from 'react-router-dom'; // 引入 Link 組件
import { useTranslation } from 'react-i18next'; // 引入 i18n
import API_URL from '../config';

function AdminDashboard({ darkMode }) {
  const [adminData, setAdminData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 i18n

  useEffect(() => {
    async function fetchAdminData() {
      try {
        const response = await axios.get(`${API_URL}/api/admin`, { withCredentials: true });
        setAdminData(response.data);
      } catch (err) {
        console.error('獲取管理員資料時發生錯誤：', err);
        if (err.response && err.response.status === 401) {
          // 未登入，重定向到登入頁面
          navigate('/login');
        } else if (err.response && err.response.status === 403) {
          // 無權限，顯示錯誤訊息或重定向
          alert(t('accessDenied'));
          navigate('/');
        }
      }
    }
    fetchAdminData();
  }, [navigate, t]);

  if (!adminData) {
    return <div>{t('loading')}</div>; // 使用 i18n 翻譯
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      {/* 頁面容器 */}
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-3xl font-bold mb-4">{t('adminDashboard')}</h1>
        {/* 導航連結 */}
        <nav className="flex flex-wrap space-x-4 mb-6">
          <Link
            to="/admin/users"
            className={`px-4 py-2 rounded ${
              darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {t('manageUsers')}
          </Link>
          <Link
            to="/admin/api-usage"
            className={`px-4 py-2 rounded ${
              darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {t('viewApiUsage')}
          </Link>
          <Link
            to="/admin/articles"
            className={`px-4 py-2 rounded ${
              darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {t('manageArticles')}
          </Link>
            <Link
                to="/admin/articles/create"
                className={`px-4 py-2 rounded ${
                darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'
                }`}
            >
                {t('createArticle')}
            </Link>
            {/* 影音管理按鈕 */}
            <Link to="/admin/videos" className={`px-4 py-2 rounded ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'}`}>
              {t('manageVideos')}
            </Link>
            <Link to="/admin/videos/create" className={`px-4 py-2 rounded ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'}`}>
              {t('createVideo')}
            </Link>
        </nav>
        {/* 統計資訊 */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div
            className={`p-4 rounded shadow ${
              darkMode ? 'bg-gray-800' : 'bg-white'
            }`}
          >
            <p className="text-xl font-semibold">{t('totalUsers')}</p>
            <p className="text-3xl">{adminData.userCount}</p>
          </div>
          <div
            className={`p-4 rounded shadow ${
              darkMode ? 'bg-gray-800' : 'bg-white'
            }`}
          >
            <p className="text-xl font-semibold">{t('totalArticles')}</p>
            <p className="text-3xl">{adminData.articleCount}</p>
          </div>
          <div className={`p-4 rounded shadow ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <p className="text-xl font-semibold">{t('totalVideos')}</p>
            <p className="text-3xl">{adminData.videoCount}</p>
          </div>
        </div>
        {/* 其他管理員功能 */}
      </div>
    </div>
  );
}

export default AdminDashboard;
