import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function ApiUsagePage({ darkMode }) {
  const { t } = useTranslation();
  const [apiUsageData, setApiUsageData] = useState([]);

  useEffect(() => {
    async function fetchApiUsage() {
      try {
        const response = await axios.get(`${API_URL}/api/admin/api-usage`, {
          withCredentials: true,
        });
        setApiUsageData(response.data);
      } catch (err) {
        console.error(t('errorFetchingApiUsage'), err);
      }
    }

    fetchApiUsage();
  }, [t]);

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-3xl font-bold mb-6 text-center">{t('apiUsageStatistics')}</h1>
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse text-sm sm:text-base">
            <thead>
              <tr className="bg-gray-200 dark:bg-gray-700">
                <th className="border p-4">{t('endpoint')}</th>
                <th className="border p-4">{t('method')}</th>
                <th className="border p-4">{t('usageCount')}</th>
                <th className="border p-4">{t('lastUsedAt')}</th>
              </tr>
            </thead>
            <tbody>
              {apiUsageData.map((usage, index) => (
                <tr key={index} className="odd:bg-white even:bg-gray-100 dark:odd:bg-gray-800 dark:even:bg-gray-700">
                  <td className="border p-4 truncate max-w-xs break-words" title={usage.endpoint}>{usage.endpoint}</td>
                  <td className="border p-4 text-center">{usage.method}</td>
                  <td className="border p-4 text-center">{usage.count}</td>
                  <td className="border p-4">{new Date(usage.lastUsedAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ApiUsagePage;
