// src/pages/AdminUsers.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import API_URL from '../config';
import { useTranslation } from 'react-i18next'; // 引入 i18n
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate

function AdminUsers() {
  const [users, setUsers] = useState([]);
  const { t } = useTranslation(); // 使用 i18n
  const navigate = useNavigate(); // 用於導航

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.get(`${API_URL}/api/admin/users`, { withCredentials: true });
        setUsers(response.data);
      } catch (err) {
        console.error('獲取用戶資料時發生錯誤：', err);
        if (err.response && err.response.status === 401) {
          // 未登入，重定向到登入頁面
          navigate('/login');
        } else if (err.response && err.response.status === 403) {
          // 無權限，顯示錯誤訊息或重定向
          alert(t('accessDenied'));
          navigate('/');
        }
      }
    }
    fetchUsers();
  }, [navigate, t]);

  const handleRoleChange = async (userId, newRole) => {
    try {
      await axios.put(`${API_URL}/api/admin/users/${userId}/role`, { role: newRole }, { withCredentials: true });
      setUsers(users.map(user => user._id === userId ? { ...user, role: newRole } : user));
    } catch (err) {
      console.error('更新用戶角色時發生錯誤：', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
      {/* 頁面容器 */}
      <div className="container mx-auto px-4 py-6">
        <h2 className="text-2xl font-bold mb-4">{t('userManagement')}</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('name')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('email')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('role')}</th>
                <th className="px-4 py-2 border-b dark:border-gray-700">{t('changeRole')}</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user._id} className="text-center">
                  <td className="px-4 py-2 border-b dark:border-gray-700">{user.name}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">{user.email}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">{t(user.role)}</td>
                  <td className="px-4 py-2 border-b dark:border-gray-700">
                    <select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user._id, e.target.value)}
                      className="p-2 rounded bg-white dark:bg-gray-800 text-black dark:text-white border dark:border-gray-600"
                    >
                      <option value="user">{t('user')}</option>
                      <option value="admin">{t('admin')}</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
