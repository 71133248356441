// frontend/src/pages/ReportDetails.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig'; //新增CSRF設定
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import MemberSidebar from '../components/MemberSidebar'; // 引入左側導航
import { saveAs } from 'file-saver'; // 用於保存文件
import Modal from 'react-modal'; // 引入 React Modal
import { toast } from "react-toastify";


// 設置模態窗口的根元素
Modal.setAppElement('#root');

function ReportDetails() {
  const { t, i18n } = useTranslation();
  const { id } = useParams(); // 從 URL 參數中獲取報告 ID
  const navigate = useNavigate();

  const [report, setReport] = useState(null);
  const [formData, setFormData] = useState(null);

  // 新增一個狀態來追蹤正在編輯的欄位
  const [editingFields, setEditingFields] = useState({});

  // 模態窗口狀態
  const [modalImage, setModalImage] = useState(null);
  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
  };
  const closeModal = () => {
    setModalImage(null);
  };

  // 追蹤移除證據文件
  const [removedEvidenceFiles, setRemovedEvidenceFiles] = useState([]);
  const [newEvidenceFiles, setNewEvidenceFiles] = useState([]);
  // 追蹤移除截圖
  const [removedTransactionScreenshots, setRemovedTransactionScreenshots] = useState({});




  useEffect(() => {
    // 檢查用戶是否已登入
    axios
      .get(`${API_URL}/api/current_user`, { withCredentials: true })
      .then((res) => {
        if (!res.data) {
          navigate('/login'); // 未登入，重定向到登入頁面
        }
      })
      .catch((err) => {
        console.error(t('errorFetchingUser'), err);
        navigate('/login');
      });

    // 獲取報告詳細資訊
    axios
      .get(`${API_URL}/api/report/${id}`, { withCredentials: true })
      .then((res) => {
        setReport(res.data);
        setFormData(res.data); // 初始化表單資料
        // console.log("報告資料:", res.data); // 確認接收到的數據
      })
      .catch((err) => {
        console.error(t('errorFetchingReport'), err);
      });
  }, [id, navigate, t]);

  // 處理輸出 PDF，將當前語言傳遞給後端
  const handleExportPDF = () => {
    axios
      .get(`${API_URL}/api/export_report/${id}?format=pdf&lang=${i18n.language}`, {
        responseType: 'blob',
        withCredentials: true,
      })
      .then((res) => {
        saveAs(res.data, `report_${id}.pdf`);
      })
      .catch((err) => {
        console.error(t('errorExportingPDF'), err);
      });
  };

  // 處理輸入變更
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  // 處理陣列類型的輸入變更
  const handleArrayChange = (field, index, subField, value) => {
    const updatedArray = [...formData[field]];
    if (subField === "transactionScreenshots") {
      // 將 FileList 轉換為陣列
      updatedArray[index][subField] = Array.from(value);
    } else {
      updatedArray[index][subField] = value;
    }
    setFormData((prevData) => ({ ...prevData, [field]: updatedArray }));
  };


  // 處理單個欄位的編輯
  const handleEditField = (field) => {
    setEditingFields((prev) => ({ ...prev, [field]: true }));
  };

  // 處理取消編輯單個欄位
  const handleCancelField = (field) => {
    setEditingFields((prev) => ({ ...prev, [field]: false }));
    // 重置該欄位的值為原始報告中的值
    setFormData((prevData) => ({ ...prevData, [field]: report[field] }));
  };

  // 處理保存單個欄位的變更
  const handleSaveField = async (field) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append(field, formData[field] || '');

      // 發送 PUT 請求
      const response = await axios.put(`${API_URL}/api/report/${id}`, dataToSend, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      // console.log("API 回應：", response.data);
      setReport((prevReport) => ({ ...prevReport, [field]: response.data[field] }));
      setFormData((prevData) => ({ ...prevData, [field]: response.data[field] }));
      setEditingFields((prev) => ({
        ...prev,
        [field]: false,
      }));
      toast.success(t("fieldUpdated"));
    } catch (err) {
      console.error(t("errorUpdatingField"), err);
    }
  };

  // 處理陣列類型的欄位編輯
  const handleEditArrayField = (field, index) => {
    setEditingFields((prev) => ({
      ...prev,
      [`${field}-${index}`]: true,
    }));
  };

  const handleCancelArrayField = (field, index) => {
    setEditingFields((prev) => ({
      ...prev,
      [`${field}-${index}`]: false,
    }));
    // 重置該陣列項目的值為原始報告中的值
    setFormData((prevData) => ({
      ...prevData,
      [field]: report[field],
    }));
  };

  // 處理保存陣列類型的欄位
  const handleSaveArrayField = async (field, index) => {
    try {
      const dataToSend = new FormData();

      // 更新該陣列項目
      const updatedArray = [...formData[field]];
      updatedArray[index] = formData[field][index];

      // 將整個陣列序列化並加入 FormData
      dataToSend.set(field, JSON.stringify(updatedArray));

      // 添加其他非陣列欄位
      dataToSend.set('incidentType', formData.incidentType || '');
      dataToSend.set('otherIncidentType', formData.otherIncidentType || '');
      dataToSend.set('description', formData.description || '');
      dataToSend.set('websites', JSON.stringify(formData.websites || []));
      dataToSend.set('tokenIDs', JSON.stringify(formData.tokenIDs || []));
      dataToSend.set('ipAddresses', JSON.stringify(formData.ipAddresses || []));
      dataToSend.set('contacts', JSON.stringify(formData.contacts || []));
      dataToSend.set('email', formData.email || '');
      dataToSend.set('fullName', formData.fullName || '');
      dataToSend.set('phoneNumber', formData.phoneNumber || '');
      dataToSend.set('country', formData.country || '');
      dataToSend.set('city', formData.city || '');


      // 處理 transactionScreenshots
    const scammerAddress = formData[field][index];
    const scammerAddressId = scammerAddress._id; // 這裡的 _id 可能是 undefined，因為前端不設置
    const files = scammerAddress.transactionScreenshots || [];
    if (scammerAddressId) { // 只有當 _id 存在時，才進行文件上傳
      files.forEach(file => {
        if (file instanceof File) {
          dataToSend.append(`transactionScreenshots[${scammerAddressId}]`, file);
        }
      });
    }

      // 將被移除的交易截圖傳送給後端
      if (removedTransactionScreenshots[scammerAddressId] && removedTransactionScreenshots[scammerAddressId].length > 0) {
        dataToSend.append('removedTransactionScreenshots', JSON.stringify({
          [scammerAddressId]: removedTransactionScreenshots[scammerAddressId]
        }));
      }




      // 發送 PUT 請求
      const response = await axios.put(`${API_URL}/api/report/${id}`, dataToSend, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      // console.log("API 回應：", response.data);
      setReport((prevReport) => ({ ...prevReport, [field]: response.data[field] }));
      setFormData((prevData) => ({ ...prevData, [field]: response.data[field] }));
      setRemovedTransactionScreenshots((prev) => ({
        ...prev,
        [scammerAddressId]: [],
      }));
      setEditingFields((prev) => ({
        ...prev,
        [`${field}-${index}`]: false,
      }));
      toast.success(t("fieldUpdated"));
    } catch (err) {
      console.error(t("errorUpdatingField"), err);
    }
  };




  // 添加新的陣列項目
  const handleAddArrayField = (field) => {
    const newItem = {};
    switch (field) {
      case 'scammerAddresses':
        newItem.victimWalletAddress = '';
        newItem.scammerWalletAddress = '';
        newItem.chain = '';
        newItem.date = '';
        newItem.amount = '';
        newItem.currency = '';
        newItem.transactionID = '';
        newItem.transactionScreenshots = [];
        break;
      case 'websites':
        newItem.url = '';
        break;
      case 'tokenIDs':
        newItem.tokenID = '';
        break;
      case 'ipAddresses':
        newItem.ip = '';
        break;
      case 'contacts':
        newItem.contact = '';
        break;
      default:
        break;
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], newItem],
    }));
  };



  // 刪除陣列項目
  const handleRemoveArrayField = async (field, index, screenshotUrl = null, scammerAddressId = null) => {
    // 移除項目
    const updatedArray = formData[field].filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      [field]: updatedArray,
    }));
  
    // 如果移除的是 transactionScreenshot，將其 URL 添加到 removedTransactionScreenshots
    if (screenshotUrl && scammerAddressId) {
      setRemovedTransactionScreenshots((prev) => {
        const updated = { ...prev };
        if (!updated[scammerAddressId]) {
          updated[scammerAddressId] = [];
        }
        updated[scammerAddressId].push(screenshotUrl);
        return updated;
      });
    }
  
    try {
      // 創建並設置 FormData
      const dataToSend = new FormData();
      dataToSend.set(field, JSON.stringify(updatedArray));
      
      // 發送 PUT 請求
      await axios.put(`${API_URL}/api/report/${id}`, dataToSend, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(t("fieldUpdated"));
    } catch (err) {
      console.error(t("errorUpdatingField"), err);
      toast.error(t("errorUpdatingField"));
    }
  };
  


  const handleRemoveTransactionScreenshot = (scammerAddressId, fileUrl) => {
    // 移除交易截圖 URL
    const updatedScreenshots = formData.scammerAddresses.map((address) => {
      if (address._id === scammerAddressId) {
        return {
          ...address,
          transactionScreenshots: address.transactionScreenshots.filter((url) => url !== fileUrl),
        };
      }
      return address;
    });
  
    setFormData((prevData) => ({
      ...prevData,
      scammerAddresses: updatedScreenshots,
    }));
  
    // 將移除的 URL 添加到 removedTransactionScreenshots
    setRemovedTransactionScreenshots((prev) => {
      const updated = { ...prev };
      if (!updated[scammerAddressId]) {
        updated[scammerAddressId] = [];
      }
      updated[scammerAddressId].push(fileUrl);
      return updated;
    });
  };



// 處理移除證據文件
const handleRemoveEvidenceFile = (fileUrl) => {
  setRemovedEvidenceFiles((prev) => [...prev, fileUrl]);
  setReport((prevReport) => ({
    ...prevReport,
    evidenceFiles: prevReport.evidenceFiles.filter((url) => url !== fileUrl),
  }));
  setFormData((prevData) => ({
    ...prevData,
    evidenceFiles: prevData.evidenceFiles.filter((url) => url !== fileUrl),
  }));
};

// 處理保存證據文件
const handleSaveEvidenceFiles = async () => {
  try {
    const dataToSend = new FormData();

    // 將移除的文件列表傳送給後端
    if (removedEvidenceFiles.length > 0) {
      dataToSend.append('removedEvidenceFiles', JSON.stringify(removedEvidenceFiles));
    }

    // 將新增的文件添加到 FormData
    newEvidenceFiles.forEach((file, index) => {
      dataToSend.append('evidenceFiles', file);
    });

    // 發送 PUT 請求
    const response = await axios.put(`${API_URL}/api/report/${id}`, dataToSend, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });

    // 更新前端狀態
    setReport(response.data);
    setFormData(response.data);
    setRemovedEvidenceFiles([]);
    setNewEvidenceFiles([]);
    setEditingFields((prev) => ({
      ...prev,
      'evidenceFiles': false,
    }));
    toast.success(t("evidenceFilesUpdated"));
  } catch (err) {
    console.error(t("errorUpdatingEvidenceFiles"), err);
    toast.error(t("errorUpdatingEvidenceFiles"));
  }
};


// 處理取消證據文件編輯
const handleCancelEvidenceFiles = () => {
  setEditingFields((prev) => ({
    ...prev,
    'evidenceFiles': false,
  }));
  setRemovedEvidenceFiles([]);
  setNewEvidenceFiles([]);
  setFormData((prevData) => ({
    ...prevData,
    evidenceFiles: report.evidenceFiles,
  }));
};




  if (!report || !formData) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      {/* 頁面橫幅 */}
      <div
        className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >
        {t('reportDetails')}
      </div>

      <div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto mt-6 px-4">
        {/* 左側頭像和導航區 */}
        <MemberSidebar activePage="myReports" />

        {/* 右側報告詳情區 */}
        <div className="flex-1 ml-0 md:ml-6">
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
            {/* 右上角的輸出 PDF 按鈕 */}
            <div className="flex justify-end mb-4">
              <button
                onClick={handleExportPDF}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t('exportAsPDF')}
              </button>
            </div>

            {/* 報告內容 */}
            <div>
              {/* 顯示報告詳細內容 */}
              <h2 className="text-2xl font-bold mb-4">
                {t('reportId')}: {report._id}
              </h2>

              {/* 使用表格顯示報告內容 */}
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-800">
                  <tbody>
                    {/* 事件類型 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 w-1/3">
                        {t('incidentType')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300 w-2/3">
                        {editingFields['incidentType'] ? (
                          <div>
                            {/* 編輯狀態下的事件類型選擇 */}
                            <select
                              value={formData.incidentType}
                              onChange={(e) => handleChange('incidentType', e.target.value)}
                              className="w-full p-2 border rounded"
                            >
                              {/* 選項列表 */}
                              <option value="">{t('pleaseSelect')}</option>
                              <option value="blackmailSextortion">{t('blackmailSextortion')}</option>
                              <option value="blackmailRansomware">{t('blackmailRansomware')}</option>
                              <option value="blackmailOther">{t('blackmailOther')}</option>
                              <option value="fraudNftAirdrop">{t('fraudNftAirdrop')}</option>
                              <option value="fraudPhishing">{t('fraudPhishing')}</option>
                              <option value="fraudImpersonation">{t('fraudImpersonation')}</option>
                              <option value="fraudDonation">{t('fraudDonation')}</option>
                              <option value="fraudRomance">{t('fraudRomance')}</option>
                              <option value="fraudPigButchering">{t('fraudPigButchering')}</option>
                              <option value="fraudFakeProject">{t('fraudFakeProject')}</option>
                              <option value="fraudRugPull">{t('fraudRugPull')}</option>
                              <option value="fraudFakeReturns">{t('fraudFakeReturns')}</option>
                              <option value="fraudOther">{t('fraudOther')}</option>
                              <option value="hackSimSwap">{t('hackSimSwap')}</option>
                              <option value="hackContractExploit">{t('hackContractExploit')}</option>
                              <option value="hackPasswordCrack">{t('hackPasswordCrack')}</option>
                              <option value="hackPrivateKeyLeak">{t('hackPrivateKeyLeak')}</option>
                              <option value="hackOther">{t('hackOther')}</option>
                              <option value="other">{t('other')}</option>
                            </select>
                            {/* 如果選擇了 "其他"，顯示輸入框 */}
                            {formData.incidentType === 'other' && (
                              <div className="mt-2">
                                <label className="block text-gray-700 dark:text-gray-300 mb-2">
                                  {t('pleaseSpecify')}
                                </label>
                                <input
                                  type="text"
                                  value={formData.otherIncidentType || ""}
                                  onChange={(e) =>
                                    handleChange('otherIncidentType', e.target.value)
                                  }
                                  className="w-full p-2 border rounded"
                                />
                              </div>
                            )}
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('incidentType')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('incidentType')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示事件類型 */}
                            <div>
                              {report.incidentType === 'other'
                                ? report.otherIncidentType || t('noData')
                                : t(report.incidentType) || t('noData')}
                            </div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('incidentType')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 案件描述 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('description')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['description'] ? (
                          <div>
                            {/* 編輯狀態下的案件描述 */}
                            <textarea
                              value={formData.description}
                              onChange={(e) => handleChange('description', e.target.value)}
                              className="w-full p-2 border rounded"
                            ></textarea>
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('description')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('description')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示案件描述 */}
                            <div>{report.description || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('description')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 證據文件 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('evidenceFiles')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['evidenceFiles'] ? (
                          <div className="border p-4 rounded">
                            {/* 上傳新的證據文件 */}
                            <div className="mb-2">
                              <label className="block text-gray-700 dark:text-gray-300">
                                {t('uploadNewEvidenceFiles')}
                              </label>
                              <input
                                type="file"
                                onChange={(e) => {
                                  setNewEvidenceFiles(Array.from(e.target.files));
                                }}
                                className="w-full p-2 border rounded"
                                multiple // 允許多文件上傳
                              />
                            </div>

                            {/* 顯示現有的證據文件並提供移除功能 */}
                            <div className="mb-2">
                              <label className="block text-gray-700 dark:text-gray-300">
                                {t('existingEvidenceFiles')}
                              </label>
                              {report.evidenceFiles && report.evidenceFiles.length > 0 ? (
                                <div className="mt-2 flex flex-wrap items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow">
                                  {report.evidenceFiles.map((fileUrl, index) => (
                                    <div key={index} className="relative mr-4 mb-2">
                                      <img
                                        src={fileUrl}
                                        alt={`Evidence ${index + 1}`}
                                        className="w-32 h-32 object-cover rounded cursor-pointer"
                                        loading="lazy"
                                        onClick={() => openModal(fileUrl)}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = '/images/default-thumbnail.png'; // 替代圖片路徑
                                        }}
                                      />
                                      {/* 移除按鈕 */}
                                      <button
                                        type="button"
                                        onClick={() => handleRemoveEvidenceFile(fileUrl)}
                                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <p className="text-sm text-gray-800 dark:text-gray-200">
                                  {t('noEvidenceFilesAvailable')}
                                </p>
                              )}
                            </div>

                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveEvidenceFiles()}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelEvidenceFiles()}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            {/* 顯示證據文件縮圖 */}
                            {report.evidenceFiles && report.evidenceFiles.length > 0 ? (
                              <div className="mt-2 flex flex-wrap items-center space-x-4">
                                {report.evidenceFiles.map((fileUrl, index) => (
                                  <div key={index} className="relative mr-4 mb-2">
                                    <img
                                      src={fileUrl}
                                      alt={`Evidence ${index + 1}`}
                                      className="w-32 h-32 object-cover rounded cursor-pointer"
                                      loading="lazy"
                                      onClick={() => openModal(fileUrl)}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/images/default-thumbnail.png'; // 替代圖片路徑
                                      }}
                                    />
                                    
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="text-sm text-gray-800 dark:text-gray-200">
                                {t('noEvidenceFilesAvailable')}
                              </p>
                            )}

                            {/* 編輯按鈕 */}
                            <div className="flex justify-end mt-2">
                              <button
                                onClick={() => handleEditField('evidenceFiles')}
                                className="text-blue-500"
                              >
                                {t('edit')}
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>


                    {/* 電子郵件 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('email')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['email'] ? (
                          <div>
                            {/* 編輯狀態下的電子郵件 */}
                            <input
                              type="email"
                              value={formData.email || ""}
                              onChange={(e) => handleChange('email', e.target.value)}
                              className="w-full p-2 border rounded"
                            />
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('email')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('email')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示電子郵件 */}
                            <div>{report.email || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('email')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 姓名 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('fullName')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['fullName'] ? (
                          <div>
                            {/* 編輯狀態下的姓名 */}
                            <input
                              type="text"
                              value={formData.fullName || ""}
                              onChange={(e) => handleChange('fullName', e.target.value)}
                              className="w-full p-2 border rounded"
                            />
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('fullName')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('fullName')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示全名 */}
                            <div>{report.fullName || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('fullName')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 電話號碼 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('phoneNumber')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['phoneNumber'] ? (
                          <div>
                            {/* 編輯狀態下的電話號碼 */}
                            <input
                              type="text"
                              value={formData.phoneNumber || ""}
                              onChange={(e) => handleChange('phoneNumber', e.target.value)}
                              className="w-full p-2 border rounded"
                            />
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('phoneNumber')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('phoneNumber')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示電話號碼 */}
                            <div>{report.phoneNumber || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('phoneNumber')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 國家 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('country')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['country'] ? (
                          <div>
                            {/* 編輯狀態下的國家 */}
                            <input
                              type="text"
                              value={formData.country || ""}
                              onChange={(e) => handleChange('country', e.target.value)}
                              className="w-full p-2 border rounded"
                            />
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('country')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('country')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示國家 */}
                            <div>{report.country || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('country')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* 城市 */}
                    <tr className="border-b">
                      <td className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                        {t('city')}
                      </td>
                      <td className="py-4 px-6 text-gray-700 dark:text-gray-300">
                        {editingFields['city'] ? (
                          <div>
                            {/* 編輯狀態下的城市 */}
                            <input
                              type="text"
                              value={formData.city || ""}
                              onChange={(e) => handleChange('city', e.target.value)}
                              className="w-full p-2 border rounded"
                            />
                            {/* 保存和取消按鈕 */}
                            <div className="mt-2 flex justify-end space-x-2">
                              <button
                                onClick={() => handleSaveField('city')}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                              >
                                {t('save')}
                              </button>
                              <button
                                onClick={() => handleCancelField('city')}
                                className="bg-gray-500 text-white px-2 py-1 rounded"
                              >
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            {/* 顯示城市 */}
                            <div>{report.city || t('noData')}</div>
                            {/* 編輯按鈕 */}
                            <button
                              onClick={() => handleEditField('city')}
                              className="text-blue-500"
                            >
                              {t('edit')}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>


                    {/* 詐騙者地址 */}
                    {report.scammerAddresses && report.scammerAddresses.length > 0 && (
                      <>
                        {/* 標題行 */}
                        <tr className="border-b">
                          <td colSpan="2" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                            {t('scammerAddresses')}
                          </td>
                        </tr>
                        {/* 詐騙者地址列表 */}
                        {formData.scammerAddresses.map((address, index) => (
                          <tr key={index} className="border-b">
                            <td colSpan="2" className="py-4 px-6">
                              {editingFields[`scammerAddresses-${index}`] ? (
                                <div className="border p-4 rounded">
                                  {/* 受害者錢包地址 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('victimWalletAddress')}
                                    </label>
                                    <input
                                      type="text"
                                      value={address.victimWalletAddress || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'victimWalletAddress',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 詐騙者錢包地址 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('scammerWalletAddress')}
                                    </label>
                                    <input
                                      type="text"
                                      value={address.scammerWalletAddress || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'scammerWalletAddress',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 區塊鏈 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('chain')}
                                    </label>
                                    <input
                                      type="text"
                                      value={address.chain || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'chain',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 日期 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('date')}
                                    </label>
                                    <input
                                      type="date"
                                      value={address.date ? address.date.slice(0,10) : ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'date',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 金額 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('amount')}
                                    </label>
                                    <input
                                      type="number"
                                      value={address.amount || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'amount',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 幣種 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('currency')}
                                    </label>
                                    <input
                                      type="text"
                                      value={address.currency || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'currency',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 交易ID */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('transactionID')}
                                    </label>
                                    <input
                                      type="text"
                                      value={address.transactionID || ""}
                                      onChange={(e) =>
                                        handleArrayChange(
                                          'scammerAddresses',
                                          index,
                                          'transactionID',
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>

                                  {/* 交易截圖 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('transactionScreenshots')}
                                    </label>
                                    {/* 編輯模式下的交易截圖上傳 */}
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        handleArrayChange('scammerAddresses', index, 'transactionScreenshots', e.target.files)
                                      }
                                      className="w-full p-2 border rounded"
                                      multiple // 允許多文件上傳
                                    />
                                    {/* 顯示已選擇的文件 */}
                                    {address.transactionScreenshots && address.transactionScreenshots.length > 0 && (
                                      <div className="mt-2 flex flex-wrap items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow">
                                        {address.transactionScreenshots
                                          .filter((file) => file)
                                          .map((file, fileIndex) => (
                                            <div key={fileIndex} className="flex items-center mr-4 mb-2">
                                              <p className="text-sm text-gray-800 dark:text-gray-200 mr-2 truncate">
                                                <strong>{t('selectedFile')}:</strong> {file instanceof File ? file.name : 'Uploaded'}
                                              </p>
                                              <button
                                                type="button"
                                                onClick={() => handleRemoveTransactionScreenshot(address._id, file)}
                                                className="text-red-500 hover:text-red-700 focus:outline-none bg-red-100 hover:bg-red-200 px-2 py-1 rounded-lg transition"
                                              >
                                                {t('remove')}
                                              </button>
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                  </div>

                                  {/* 保存和取消按鈕 */}
                                  <div className="mt-2 flex justify-end space-x-2">
                                    <button
                                      onClick={() => handleSaveArrayField('scammerAddresses', index)}
                                      className="bg-blue-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('save')}
                                    </button>
                                    <button
                                      onClick={() => handleCancelArrayField('scammerAddresses', index)}
                                      className="bg-gray-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('cancel')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {/* 顯示詐騙者地址信息 */}
                                  <div className="mb-2">
                                    <p>
                                      <strong>{t('victimWalletAddress')}:</strong> {address.victimWalletAddress || t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('scammerWalletAddress')}:</strong> {address.scammerWalletAddress || t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('chain')}:</strong> {address.chain || t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('date')}:</strong> {address.date ? new Date(address.date).toLocaleDateString() : t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('amount')}:</strong> {address.amount || t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('currency')}:</strong> {address.currency || t('noData')}
                                    </p>
                                    <p>
                                      <strong>{t('transactionID')}:</strong> {address.transactionID || t('noData')}
                                    </p>
                                  </div>

                                  {/* 交易截圖顯示 */}
                                  <div className="mb-2">
                                    <strong>{t('transactionScreenshots')}:</strong>
                                    {address.transactionScreenshots && address.transactionScreenshots.length > 0 ? (
                                      <div className="mt-2 flex flex-wrap items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow">
                                        {address.transactionScreenshots.map((file, fileIndex) => (
                                          <div key={fileIndex} className="mr-4 mb-2">
                                            <img
                                              src={file} // 假設 file 是圖片的 URL
                                              alt={`Screenshot ${fileIndex + 1}`}
                                              className="w-32 h-32 object-cover rounded cursor-pointer"
                                              loading="lazy"
                                              onClick={() => openModal(file)}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '/images/default-thumbnail.png'; // 替代圖片路徑
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <p className="text-sm text-gray-800 dark:text-gray-200">
                                        {t('noScreenshotsAvailable')}
                                      </p>
                                    )}
                                  </div>

                                  {/* 編輯和刪除按鈕 */}
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() => handleEditArrayField('scammerAddresses', index)}
                                      className="text-blue-500"
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      onClick={() => handleRemoveArrayField('scammerAddresses', index)}
                                      className="text-red-500"
                                    >
                                      {t('delete')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* 添加新的詐騙者地址 */}
                        <tr>
                          <td colSpan="2" className="py-2 px-6">
                            <button
                              onClick={() => handleAddArrayField('scammerAddresses')}
                              className="text-blue-500"
                            >
                              ＋ {t('addTransactions')}
                            </button>
                          </td>
                        </tr>
                      </>
                    )}


                    {/* 網站列表 */}
                    {report.websites && report.websites.length > 0 && (
                      <>
                        {/* 標題行 */}
                        <tr className="border-b">
                          <td colSpan="2" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                            {t('websites')}
                          </td>
                        </tr>
                        {/* 網站列表 */}
                        {formData.websites.map((website, index) => (
                          <tr key={index} className="border-b">
                            <td colSpan="2" className="py-4 px-6">
                              {editingFields[`websites-${index}`] ? (
                                <div className="border p-4 rounded">
                                  {/* 網站 URL */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('website')}
                                    </label>
                                    <input
                                      type="text"
                                      value={website.url || ""}
                                      onChange={(e) =>
                                        handleArrayChange('websites', index, 'url', e.target.value)
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 保存和取消按鈕 */}
                                  <div className="mt-2 flex justify-end space-x-2">
                                    <button
                                      onClick={() => handleSaveArrayField('websites', index)}
                                      className="bg-blue-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('save')}
                                    </button>
                                    <button
                                      onClick={() => handleCancelArrayField('websites', index)}
                                      className="bg-gray-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('cancel')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center">
                                  {/* 顯示網站 URL */}
                                  <div>
                                    <p>{website.url || t('noData')}</p>
                                  </div>
                                  {/* 編輯和刪除按鈕 */}
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() => handleEditArrayField('websites', index)}
                                      className="text-blue-500"
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      onClick={() => handleRemoveArrayField('websites', index)}
                                      className="text-red-500"
                                    >
                                      {t('delete')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* 添加新的網站 */}
                        <tr>
                          <td colSpan="2" className="py-2 px-6">
                            <button
                              onClick={() => handleAddArrayField('websites')}
                              className="text-blue-500"
                            >
                              ＋ {t('addWebsite')}
                            </button>
                          </td>
                        </tr>
                      </>
                    )}

                    {/* 代幣ID */}
                    {report.tokenIDs && report.tokenIDs.length > 0 && (
                      <>
                        {/* 標題行 */}
                        <tr className="border-b">
                          <td colSpan="2" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                            {t('tokenIDs')}
                          </td>
                        </tr>
                        {/* 代幣ID列表 */}
                        {formData.tokenIDs.map((token, index) => (
                          <tr key={index} className="border-b">
                            <td colSpan="2" className="py-4 px-6">
                              {editingFields[`tokenIDs-${index}`] ? (
                                <div className="border p-4 rounded">
                                  {/* 代幣ID */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('tokenID')}
                                    </label>
                                    <input
                                      type="text"
                                      value={token.tokenID || ""}
                                      onChange={(e) =>
                                        handleArrayChange('tokenIDs', index, 'tokenID', e.target.value)
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 保存和取消按鈕 */}
                                  <div className="mt-2 flex justify-end space-x-2">
                                    <button
                                      onClick={() => handleSaveArrayField('tokenIDs', index)}
                                      className="bg-blue-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('save')}
                                    </button>
                                    <button
                                      onClick={() => handleCancelArrayField('tokenIDs', index)}
                                      className="bg-gray-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('cancel')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center">
                                  {/* 顯示代幣ID */}
                                  <div>
                                    <p>{token.tokenID || t('noData')}</p>
                                  </div>
                                  {/* 編輯和刪除按鈕 */}
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() => handleEditArrayField('tokenIDs', index)}
                                      className="text-blue-500"
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      onClick={() => handleRemoveArrayField('tokenIDs', index)}
                                      className="text-red-500"
                                    >
                                      {t('delete')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* 添加新的代幣ID */}
                        <tr>
                          <td colSpan="2" className="py-2 px-6">
                            <button
                              onClick={() => handleAddArrayField('tokenIDs')}
                              className="text-blue-500"
                            >
                              ＋ {t('addTokenID')}
                            </button>
                          </td>
                        </tr>
                      </>
                    )}

                    {/* IP 地址 */}
                    {report.ipAddresses && report.ipAddresses.length > 0 && (
                      <>
                        {/* 標題行 */}
                        <tr className="border-b">
                          <td colSpan="2" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                            {t('ipAddresses')}
                          </td>
                        </tr>
                        {/* IP 地址列表 */}
                        {formData.ipAddresses.map((ip, index) => (
                          <tr key={index} className="border-b">
                            <td colSpan="2" className="py-4 px-6">
                              {editingFields[`ipAddresses-${index}`] ? (
                                <div className="border p-4 rounded">
                                  {/* IP 地址 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('ipAddress')}
                                    </label>
                                    <input
                                      type="text"
                                      value={ip.ip || ""}
                                      onChange={(e) =>
                                        handleArrayChange('ipAddresses', index, 'ip', e.target.value)
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 保存和取消按鈕 */}
                                  <div className="mt-2 flex justify-end space-x-2">
                                    <button
                                      onClick={() => handleSaveArrayField('ipAddresses', index)}
                                      className="bg-blue-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('save')}
                                    </button>
                                    <button
                                      onClick={() => handleCancelArrayField('ipAddresses', index)}
                                      className="bg-gray-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('cancel')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center">
                                  {/* 顯示 IP 地址 */}
                                  <div>
                                    <p>{ip.ip || t('noData')}</p>
                                  </div>
                                  {/* 編輯和刪除按鈕 */}
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() => handleEditArrayField('ipAddresses', index)}
                                      className="text-blue-500"
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      onClick={() => handleRemoveArrayField('ipAddresses', index)}
                                      className="text-red-500"
                                    >
                                      {t('delete')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* 添加新的 IP 地址 */}
                        <tr>
                          <td colSpan="2" className="py-2 px-6">
                            <button
                              onClick={() => handleAddArrayField('ipAddresses')}
                              className="text-blue-500"
                            >
                              ＋ {t('addIpAddress')}
                            </button>
                          </td>
                        </tr>
                      </>
                    )}

                    {/* 聯絡方式 */}
                    {report.contacts && report.contacts.length > 0 && (
                      <>
                        {/* 標題行 */}
                        <tr className="border-b">
                          <td colSpan="2" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200">
                            {t('contacts')}
                          </td>
                        </tr>
                        {/* 聯絡方式列表 */}
                        {formData.contacts.map((contact, index) => (
                          <tr key={index} className="border-b">
                            <td colSpan="2" className="py-4 px-6">
                              {editingFields[`contacts-${index}`] ? (
                                <div className="border p-4 rounded">
                                  {/* 聯絡方式 */}
                                  <div className="mb-2">
                                    <label className="block text-gray-700 dark:text-gray-300">
                                      {t('contact')}
                                    </label>
                                    <input
                                      type="text"
                                      value={contact.contact || ""}
                                      onChange={(e) =>
                                        handleArrayChange('contacts', index, 'contact', e.target.value)
                                      }
                                      className="w-full p-2 border rounded"
                                    />
                                  </div>
                                  {/* 保存和取消按鈕 */}
                                  <div className="mt-2 flex justify-end space-x-2">
                                    <button
                                      onClick={() => handleSaveArrayField('contacts', index)}
                                      className="bg-blue-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('save')}
                                    </button>
                                    <button
                                      onClick={() => handleCancelArrayField('contacts', index)}
                                      className="bg-gray-500 text-white px-2 py-1 rounded"
                                    >
                                      {t('cancel')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center">
                                  {/* 顯示聯絡方式 */}
                                  <div>
                                    <p>{contact.contact || t('noData')}</p>
                                  </div>
                                  {/* 編輯和刪除按鈕 */}
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() => handleEditArrayField('contacts', index)}
                                      className="text-blue-500"
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      onClick={() => handleRemoveArrayField('contacts', index)}
                                      className="text-red-500"
                                    >
                                      {t('delete')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* 添加新的聯絡方式 */}
                        <tr>
                          <td colSpan="2" className="py-2 px-6">
                            <button
                              onClick={() => handleAddArrayField('contacts')}
                              className="text-blue-500"
                            >
                              ＋ {t('addContact')}
                            </button>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 模態窗口顯示大圖 */}
      <Modal
        isOpen={!!modalImage}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 mt-2 mr-2 text-white bg-red-600 rounded-full p-2 hover:bg-red-700 focus:outline-none"
          >
            &times;
          </button>
          <img
            src={modalImage}
            alt="Enlarged Screenshot"
            className="max-w-full max-h-screen object-contain rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/images/default-thumbnail.png'; // 替代圖片路徑
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
export default ReportDetails;
