// frontend/src/utils/axiosConfig.js
import axios from 'axios';
import API_URL from '../config';

// 創建 Axios 實例
const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true, // 確保攜帶 Cookies
});

let csrfToken = ''; // 全局存儲 CSRF Token

// 獲取 CSRF Token
export const fetchCsrfToken = async () => {
  try {
    const response = await instance.get('/api/csrf-token');
    csrfToken = response.data.csrfToken;
    // console.log('CSRF token fetched:', csrfToken);
  } catch (err) {
    console.error('Failed to fetch CSRF token:', err);
    throw err; // 拋出錯誤以便調用者處理
  }
};

// 添加請求攔截器以攜帶 CSRF Token
instance.interceptors.request.use(
  (config) => {
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken; // 添加 CSRF Token 到請求標頭
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 添加響應攔截器以處理 CSRF 錯誤
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      console.error('CSRF token invalid or missing:', error.response);
      return fetchCsrfToken()
        .then(() => {
          const retryConfig = { ...error.config };
          retryConfig.headers['X-CSRF-Token'] = csrfToken; // 更新 CSRF Token
          return instance.request(retryConfig); // 重試請求
        })
        .catch((fetchError) => {
          console.error('Failed to refresh CSRF token:', fetchError);
          return Promise.reject(fetchError);
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
